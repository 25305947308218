<template>
    <div class="landing-page">
        <!-- header -->
        <div class="header">
            <div class="container d-flex align-items-center">
                <div class="menu">
                    <div class="logo">
                        <img class="img-logo-header" src="../../../assets/icons/football/ic_logoKickdun.png">
                    </div>
                    <div class="menu-list mobile">
                        <Dropdown class="language" v-model="lang" :options="langArr" optionLabel="label">
                            <template #value="slotProps">
                                <div class="country-item country-item-value">
                                    <img :src="slotProps.value.flag" class="flat-icon"/>
                                    <span class="lang-text">{{slotProps.value.label}}</span>
                                </div>
                            </template>
                            <template #option="slotProps">
                                <div class="country-item country-item-value">
                                    <img :src="slotProps.option.flag" class="flat-icon"/>
                                    <span class="lang-text">{{slotProps.option.label}}</span>
                                </div>
                            </template>
                        </Dropdown>
                        <i class="pi pi-bars" @click="showMenuListItems = true" v-if="!showMenuListItems"></i>
                        <i class="pi pi-times" @click="showMenuListItems = false" v-else></i>
                    </div>
                    <ul class="menu-list desktop">
                        <li class="menu-items" v-for="(items, index) in menuItems" :key="index">
                            <a @click="scrollMenuItems($event, items.link)">{{items.name}}</a>
                        </li>
                    </ul>
                </div>
                <div class="desktop download-menu">
                    <Dropdown class="language" v-model="lang" :options="langArr" optionLabel="label">
                        <template #value="slotProps">
                            <div class="country-item country-item-value">
                                <img :src="slotProps.value.flag" class="flat-icon"/>
                                <span class="lang-text">{{slotProps.value.label}}</span>
                            </div>
                        </template>
                        <template #option="slotProps">
                            <div class="country-item country-item-value">
                                <img :src="slotProps.option.flag" class="flat-icon"/>
                                <span class="lang-text">{{slotProps.option.label}}</span>
                            </div>
                        </template>
                    </Dropdown>
                    <div class="download-btn">
                        <span class="download-text">TẢI NGAY</span>
                        <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/ic_apple.png" class="download-icon" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/ic_android.png" class="download-icon" /></a>
                    </div>
                </div>
            </div>
            <div class="menu-list-items" v-if="showMenuListItems">
                <ul class="menu-list mobile">
                    <li class="menu-items" v-for="(items, index) in menuItems" :key="index">
                        <a @click="scrollMenuItems($event, items.link)">{{items.name}}</a>
                    </li>
                </ul>
                <div class="mobile download-menu">
                    <div class="download-btn">
                        <span class="download-text">TẢI NGAY</span>
                        <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/ic_apple.png" class="download-icon" /></a>
                        <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/ic_android.png" class="download-icon" /></a>
                    </div>
                </div>
            </div>
        </div>
        <div class="head-banners">
            <!-- <div class="mr-0 background-header"></div> -->
            <img src="../../../assets/icons/football/img_bannerhome.png" style="width: 100%; height: 100%;" />
            <div class="container">
                <div class="text-banner col-md-6">
                    <div class="header-text-banner">
                        <div>Kickdun là dịch vụ cộng đồng <br/> Bóng đá và Futsal</div>
                    </div>
                    <div class="body-text-banner">
                        Bạn đang tìm kiếm câu lạc bộ và thành viên để chơi cùng? Hãy tham gia cùng chúng tôi. <br/>Bạn có thể nhanh chóng và dễ dàng tạo câu lạc bộ, tìm đối thủ và thưởng thức các trận đấu.
                    </div>
                    <!--<div class="footer-text-banner">
                        <Button label="Xem thêm" class="p-button-success main-style-button" />
                    </div>-->
                </div>
                <div class="text-banner right col-md-6">
                    <img src="../../../assets/icons/football/banner_image.png" style="height: 85%;" />
                </div>
            </div>
        </div>
        <!-- body -->
        <div class="body">
            <div id="app-featureds">
                <div class="function-title">TÍNH NĂNG CỦA ỨNG DỤNG</div>
                <div class="container">
                    <div class="function">
                        <div class="function-banner desktop">
                            <!-- <img class="function-img" src="../../../assets/icons/football/img_appscreen.png"/> -->
                            <slider ref="slider" :options="options">
                                <!-- slideritem wrapped package with the components you need -->
                                <slideritem v-for="(item,index) in imgArr" :key="index">
                                    <img class="function-img" :src="item.img"/>
                                </slideritem>
                                <!-- Customizable loading -->
                                <div slot="loading">loading...</div>
                            </slider>
                        </div>
                        <div class="function-box">
                            <div class="function-items" :class="`${[(functions.length-1), (functions.length-2)].includes(index) ? 'last-items' : ''}`" v-for="(items, index) in functions" :key="index">
                                <div class="items-header">
                                    <img class="items-header-icon" :src="items.icon"/>
                                </div>
                                <div class="items-body">
                                    <div class="items-title">
                                        {{items.title}}
                                    </div>
                                    <div class="items-content">
                                        {{items.content}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="work">
                <div class="function-title">ỨNG DỤNG NÀY HOẠT ĐỘNG NHƯ THẾ NÀO</div>
                <div class="container">
                    <div class="function">
                        <div class="partner-box" v-for="(partner, index) in partners" :key="index">
                            <div class="partner-header">
                                <img class="partner-logo" :src="partner.logo"/>
                            </div>
                            <div class="partner-sub-header">
                                <!-- <img class="partner-logo" :src="partner.logo"/> -->
                                {{ partner.title }}
                                <div class="partner-box-line"></div>
                            </div>
                            <div class="partner-body">
                                {{ partner.content }}
                            </div>
                            <!-- <div class="partner-footer">
                                <div class="partner-box-line"></div>
                                <a href="#">Techtronic Industries</a>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="container banners">
                <div class="text-banner">
                    TẢI ỨNG DỤNG ĐỂ TRẢI NGHIỆM CÁC TÍNH NĂNG TIỆN LỢI
                </div>
                <!-- <div class="download-btn"> -->
                    <!-- <span class="download-text">TẢI NGAY</span>
                    <img src="../../../assets/icons/football/ic_apple.png" class="download-icon" />
                    <img src="../../../assets/icons/football/ic_android.png" class="download-icon" /> -->
                <!-- </div> -->
                <div class="d-flex mt-3 mb-3 justify-content-center">
                    <div class="download-icon mr-3">
                        <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/app-chplay-1.png" /></a>
                    </div>
                    <div class="download-icon">
                        <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/app-ios-1.png" /></a>
                    </div>
                </div>
            </div>

            
        </div>

        <!-- footer -->
        <div class="footer">
            <div class="container row m-auto pt-5">
                <div class="col-lg-6 col-12 pt-2 mb-4 px-0">
                    <div class="logo-footer">
                        <img class="img-logo-footer" src="../../../assets/icons/football/Logo_kickdun_white.png"/>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 px-0 pb-3">
                    <div class="about-us">
                        Kickdun, ứng dụng di động tuyệt vời dành cho những người đam mê bóng đá! Kickdun được thiết kế để tập hợp cộng đồng bóng đá lại với nhau, cung cấp nền tảng liền mạch để tạo câu lạc bộ bóng đá, tìm đồng đội, thách đấu với đối thủ và cập nhật tin tức mới nhất của đội.
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 px-0 pb-3">
                    <div class="company-name">KICKDUN COMPANY</div>
                    <div class="company-info mt-2 mb-2">
                        <div class="email">Email: kickdungo@gmail.com</div>
                        <div class="policy"><a @click="goPolicy()">Chính sách bảo mật</a></div>
                        <div class="d-flex" style="margin-top: 10px;">
                            <div class="social-icon mr-3">
                                <a href="https://www.facebook.com/profile.php?id=61558079368197&sk=about" target="_blank"><img src="../../../assets/icons/football/ic_facebook.png" /></a>
                            </div>
                            <div class="social-icon">
                                <a href="https://www.instagram.com/kickdun" target="_blank"><img src="../../../assets/icons/football/ic_instagram.png" /></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 px-0 pb-3">
                    <div class="d-flex mb-5">
                        <div class="download-icon mr-3">
                            <a href="https://play.google.com/store/apps/details?id=com.football.kickdun" target="_blank"><img src="../../../assets/icons/football/app-chplay-1.png" /></a>
                        </div>
                        <div class="download-icon">
                            <a href="https://apps.apple.com/us/app/kickdun/id6477423636" target="_blank"><img src="../../../assets/icons/football/app-ios-1.png" /></a>
                        </div>
                    </div>
                    <!--<div class="d-flex">
                        <div class="social-icon mr-3">
                            <a href="https://www.facebook.com/profile.php?id=61558079368197&sk=about" target="_blank"><img src="../../../assets/icons/football/ic_facebook.png" /></a>
                        </div>
                        <div class="social-icon">
                            <a href="https://www.instagram.com/kickdun" target="_blank"><img src="../../../assets/icons/football/ic_instagram.png" /></a>
                        </div>
                    </div>-->
                </div>

                <div class="col-12 px-0">
                    <div class="copy-right">
                        Copyright by KICKDUN @2024
                    </div>
                </div>
            </div>
        </div>
        <div class="menu-shadow" v-if="showMenuListItems" @click="showMenuListItems = false"></div>
    </div>
</template>

<script>
import jq from "jquery";
import { slider, slideritem } from 'vue-concise-slider'
export default {
    name: "home",
    components: {
        slider,
        slideritem
    },
    data() {
        return {
            options: {
                currentPage: 0,
                speed: 300,
                autoplay: 2000,
                itemAnimation: true,
                centeredSlides: true,
                thresholdDistance: 100,
                thresholdTime: 300,
                loopedSlides: 2,
                slidesToScroll: 1,
                loop: true
            },
            imgArr: [
                {
                    //img: require("@/assets/icons/football/img_appscreen.png"),
                    img: require("@/assets/icons/football/img_community.png"),
                },
                {
                    //img: require("@/assets/icons/football/img_clubdetailscreen.png")
                    img: require("@/assets/icons/football/img_match.png")
                },
                {
                    //img: require("@/assets/icons/football/banner_image.png")
                    img: require("@/assets/icons/football/img_ranking.png")
                },
                {
                    img: require("@/assets/icons/football/img_review.png")
                },
            ],
            lang: {
                label: 'VI',
                value: 'vi',
                flag: require("@/assets/icons/football/flag_VN.png")
            },
            showMenuListItems: false,
            langArr: [
                {
                    label: 'ENG',
                    value: 'en',
                    flag: require("@/assets/icons/football/flag_UK.png")
                },
                {
                    label: 'VI',
                    value: 'vi',
                    flag: require("@/assets/icons/football/flag_VN.png")
                },
                {
                    label: 'KR',
                    value: 'kr',
                    flag: require("@/assets/icons/football/flag_KR.png")
                }
            ],
            menuItems: [
                {
                    link: "#app-featureds",
                    name: "TÍNH NĂNG CỦA ỨNG DỤNG"
                },
                {
                    link: "#work",
                    name: "ỨNG DỤNG NÀY HOẠT ĐỘNG NHƯ THẾ NÀO"
                },
                // {
                //     link: "#work",
                //     name: "Đối tác"
                // }
            ],
            partners: [
                {   
                    title: "Tạo câu lạc bộ bóng đá",
                    logo: require("@/assets/icons/football/football-badge-svgrepo-com.png"),
                    content: "Bắt đầu câu lạc bộ bóng đá của riêng bạn chỉ với một vài thao tác chạm. Tùy chỉnh hồ sơ câu lạc bộ của bạn, bao gồm tên đội, logo và màu sắc. Mời bạn bè hoặc người dùng khác tham gia câu lạc bộ của bạn và quản lý các thành viên trong đội một cách dễ dàng."
                },
                {
                    title: "Tìm đồng đội",
                    logo: require("@/assets/icons/football/groups_black_24dp_outline.png"),
                    content: "Bạn có thiếu cầu thủ cho trận đấu tiếp theo không? Sử dụng ứng dụng của chúng tôi để tìm kiếm và kết nối với các đồng đội tiềm năng. Lọc tìm kiếm theo vị trí, trình độ kỹ năng và tình trạng sẵn sàng để tìm ra sự kết hợp hoàn hảo cho đội của bạn."
                },
                {
                    title: "Thách thức đối thủ",
                    logo: require("@/assets/icons/football/soccer-ball-svgrepo-com (2).png"),
                    content: "Sẵn sàng thử thách kỹ năng của bạn? Tìm và thách đấu các đội khác trong khu vực của bạn. Ứng dụng của chúng tôi cho phép bạn lên lịch các trận đấu, thiết lập địa điểm và giao tiếp với các đội đối thủ để sắp xếp thời gian tốt nhất cho một trận đấu."
                },
                {
                    title: "Xếp hạng và Bảng xếp hạng",
                    logo: require("@/assets/icons/football/ranking-svgrepo-com.png"),
                    content: "Theo dõi hiệu suất của đội bạn và xem bạn xếp hạng như thế nào so với những đội khác. Hệ thống xếp hạng của chúng tôi dựa trên kết quả trận đấu, cho phép bạn leo lên bảng xếp hạng và được công nhận trong cộng đồng bóng đá."
                }
            ],
            packages: [],
            typeArr: {
                "DAY": "ngày",
                "MONTH": "tháng",
                "YEAR": "năm",
                "WEEK": "tuần"
            },
            functions: [
                {
                    icon: require("@/assets/icons/football/ic_findingoppoent.png"),
                    title: "Tìm đối thủ và lính đánh thuê",
                    content: "Bạn có thể tìm đội đối thủ bằng cách chọn khu vực, trình độ kỹ năng, giới tính và độ tuổi mong muốn."
                },
                {
                    icon: require("@/assets/icons/football/ic_clubmanagement.png"),
                    title: "Quản lý câu lạc bộ",
                    content: "Chúng tôi cung cấp trang câu lạc bộ nơi bạn có thể giao tiếp với các thành viên trong nhóm và quản lý câu lạc bộ của mình."
                },
                {
                    icon: require("@/assets/icons/football/ic_community.png"),
                    title: "Cộng đồng",
                    content: "Chia sẻ tin tức bóng đá, futsal và những câu chuyện thú vị."
                },
                {
                    icon: require("@/assets/icons/football/ic_clubranking.png"),
                    title: "Xếp hạng & Đánh giá Câu lạc bộ",
                    content: "Kiểm tra thứ hạng của câu lạc bộ thông qua kết quả trận đấu. Thành tích của câu lạc bộ bạn sẽ tiếp tục được cải thiện."
                }
            ]
        }
    },
    watch: {
        "lang"() {
            this.$router.push(`/${this.lang.value}`);
        },
        "$route"() {
            setTimeout(() => {
                const hash = Object.assign({}, this.$route.hash);
                if (hash) {
                    history.pushState("", document.title, window.location.pathname + window.location.search);
                }
            }, 500);
        }
    },
    async created() {
        setTimeout(() => {
            const hash = Object.assign({}, this.$route.hash);
            if (hash) {
                history.pushState("", document.title, window.location.pathname + window.location.search);
            }
        }, 500);
        jq("#planding .function").on('scroll',function(){
            var WindowLeft = jq("#planding .function").scrollLeft();
            var stop = false;
            jq('.planding-box').each(function(i){
                var left = jq('.planding-box').eq(i).offset().left;
                var eleWidth = jq('.planding-box').eq(i).width();
                var scrollLeft = (left + (eleWidth + (10*i)))*i;
                if (scrollLeft < 0) {
                    scrollLeft = 0;
                }
                if(WindowLeft <= scrollLeft && !stop
                ){
                    jq('.planding-box').removeClass('active');
                    jq('.planding-page-item').removeClass('active');
                    jq('.planding-nav .planding-page-item').eq(i).addClass('active');
                    jq('.planding-box').eq(i).addClass('active');
                    stop = true;
                }
            });
        });
    },
    methods: {
        goPolicy() {
            document.title = "Kickdun Policy";
            this.$router.push('/policy');
        },
        // changeLanguage () {
        //     if (this.lang_btn == 'en') {
        //         this.lang_btn = 'vi';
        //     } else {
        //         this.lang_btn = 'en';
        //     }
        // },
        async clickPlandingNav(e, id) {
            jq("#planding .function").animate({
                scrollLeft: '+='+jq(`#planding .function #page-${id}`).offset().left
            }, 0);
            e.stopPropagation();
        },
        async scrollMenuItems(e, link) {
            history.pushState("", document.title, window.location.pathname + window.location.search);
            this.showMenuListItems = false;
            jq("html").animate({
                scrollTop: (jq(link).offset().top-90)
            }, 300);
            e.preventDefault();
        },
    }
};
</script>
